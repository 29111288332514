import './app.scss';
import './images';
import * as perfundo from 'perfundo';
import * as _throttle from 'lodash/throttle'
import {createCalendar} from "./calendar";

import firebase from "firebase/app";
import 'firebase/firestore';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyDGU7Kqmdwo-mbf6-ZM33KepyNvpwqXvwE",
    authDomain: "sliueruce.firebaseapp.com",
    projectId: "sliueruce",
    storageBucket: "sliueruce.appspot.com",
    messagingSenderId: "279761228035",
    appId: "1:279761228035:web:ac3f1e9fa49ad5362a9936",
    measurementId: "G-9HLPCXLJWZ"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();


perfundo.default('.perfundo', {
    // This are the default options.
    disableHistory: true,
    swipe: true,
    keyboard: true,
    classNames: {
        link: `perfundo__link`,
        overlay: `perfundo__overlay`,
        close: `perfundo__close`,
        prev: `perfundo__prev`,
        next: `perfundo__next`,
        active: `is-active`
    }
});

let prevScrollpos = window.pageYOffset;
window.onscroll = _throttle(() => {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        document.getElementsByClassName("navbar")[0].style.top = "0";
    } else {
        document.getElementsByClassName("navbar")[0].style.top = "-52px";
    }
    console.log('prev', prevScrollpos);
    prevScrollpos = currentScrollPos;
    console.log('current', currentScrollPos);
}, 500);

document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

});

document.querySelector('form').addEventListener('submit', onContactSubmitted);

const promises = [
    db.collection("high").get(),
    db.collection("low").get(),
    db.collection("medium").get(),
    db.collection("booked").get(),
    db.collection("unavailable").get()
];

Promise.all(promises).then((data, error) => {
    createCalendar(data);
})

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

function onContactSubmitted(e) {
    e.preventDefault();

    const defaultBtnCls = 'button is-fullwidth';

    const button = e.target.querySelector('button');
    button.className = `${defaultBtnCls} is-loading`;

    const trap = document.querySelector('input[name=bamboo]').value;

    if (trap) {
        return;
    }

    return axios.post('https://europe-west1-sliueruce.cloudfunctions.net/sendMailEu', {
        email: document.querySelector('input[name=email]').value,
        name: document.querySelector('input[name=name]').value,
        tel: document.querySelector('input[name=tel]').value,
        message: document.querySelector('textarea[name=message]').value,
        trap: trap
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    }).then(() => {
        button.className = `${defaultBtnCls} is-success`;
        button.textContent = 'Message envoyé !';
        button.disabled = true;
    }).catch(() => {
        button.className = `${defaultBtnCls} is-primary is-error`;
        button.textContent = 'Envoyer';
    });



    return false;
}
