import getDay from 'date-fns/getDay'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import { fr } from 'date-fns/locale';
import isToday from 'date-fns/isToday';

const today = new Date();
let currentMonth = today.getMonth();
let currentYear = today.getFullYear();

let periods = {};


const incrementMonth = () => {

    const newMonth = currentMonth < 11 ? currentMonth + 1 : 0;
    const newYear = currentMonth < 11 ? currentYear : currentYear + 1;

    currentMonth = newMonth;
    currentYear = newYear;
}

const decrementMonth = () => {
    const newMonth = currentMonth > 0 ? currentMonth - 1 : 11;
    const newYear = currentMonth > 0 ? currentYear : currentYear - 1;

    currentMonth = newMonth;
    currentYear = newYear;
}

const getDaysPerMonth = (year, monthIdx) => {
    return [
        31,
        year % 4 === 0 ? 29 : 28,
        31, 30, 31, 30, 31, 31,
        30, 31, 30, 31
    ][monthIdx];
}

const getFirstAndLastDayOfMonth = (year, monthIdx) => {
    const dte = new Date(year, monthIdx, 1);
    console.log(dte);
    const firstDay = getDay(dte);
    const lastDay = getDay(addDays(dte, getDaysPerMonth(year, monthIdx) - 1));
    return [firstDay, lastDay];
}

const normalizeDate = (dte) => {
    dte.setHours(0);
    dte.setMinutes(0);
    dte.setSeconds(0);
    dte.setMilliseconds(0);
}

const buildDays = (year, monthIdx) => {
    const [first, last] = getFirstAndLastDayOfMonth(year, monthIdx);
    const daysInMonth = getDaysPerMonth(year, monthIdx);
    const nbEmptyStart = first === 0 ? 6 : (first - 1);
    console.log('nbEmptyStart', nbEmptyStart);
    const nbEmptyEnd = last === 0 ? 0 : 7 - last;
    console.log('nbEmptyEnd', nbEmptyEnd);
    const days = [];
    console.log('daysInMonth', daysInMonth);
    for (let i = 1; i <= (daysInMonth + nbEmptyEnd + nbEmptyStart); i++) {
        debugger;
        const elem = document.createElement('div');
        const classList = ['calendar__number'];

        const dayNbr = i - nbEmptyStart;
        const dayNbrTxt = dayNbr >= 1 && dayNbr <= daysInMonth ? dayNbr : '';
        console.log('dayNbrTxt', dayNbrTxt, i);

        const correspondingDate = dayNbrTxt ? new Date(currentYear, currentMonth, dayNbr) : null;

        if (correspondingDate) {

            normalizeDate(correspondingDate);

            if (isDateInPeriod('booked', correspondingDate) || isDateInPeriod('unavailable', correspondingDate) || correspondingDate.getTime() < today.getTime()) {
                classList.push('calendar__number--disabled');
            } else {
                if (isDateInPeriod('low', correspondingDate)) {
                    classList.push('calendar__number--low');
                }

                if (isDateInPeriod('medium', correspondingDate)) {
                    classList.push('calendar__number--medium');
                }

                if (isDateInPeriod('high', correspondingDate)) {
                    classList.push('calendar__number--high');
                }
            }

            if (isToday(correspondingDate)) {
                classList.push('calendar__number--current');
            }
        }

        for (let className of classList) {
            elem.classList.add(className);
        }

        elem.textContent = dayNbrTxt;
        days.push(elem);
    }
    return days;
}


const prevBtn = document.getElementById('calendar-prev');
const nextBtn = document.getElementById('calendar-next');

for (let btn of [prevBtn, nextBtn]) {
    btn.onclick = () => {
        btn.id.includes('prev') && decrementMonth();
        btn.id.includes('next') && incrementMonth();
        createCalendar();
    }
}

function removeChildren (params){
    var parent = params.parent;
    var childName = params.childName;

    var childNodesToRemove = parent.getElementsByClassName(childName);
    for(var i=childNodesToRemove.length-1;i >= 0;i--){
        var childNode = childNodesToRemove[i];
        childNode.parentNode.removeChild(childNode);
    }
}

function isDateInPeriod(periodName, date) {
    return periods[periodName] && periods[periodName].find(item => {
        const fromDate = new Date(item.data.from.toDate());
        const toDate = new Date(item.data.to.toDate());

        normalizeDate(fromDate);
        normalizeDate(toDate);

        return fromDate.getTime() <= date.getTime() && toDate.getTime() >= date.getTime()
    });
}

export const createCalendar = (periodsParam) => {

    if (periodsParam) {
        const [high, low, medium, booked, unavailable] = periodsParam;
        high.forEach(transformData.bind(null, 'high'));
        low.forEach(transformData.bind(null, 'low'));
        medium.forEach(transformData.bind(null, 'medium'));
        booked.forEach(transformData.bind(null, 'booked'));
        unavailable.forEach(transformData.bind(null, 'unavailable'));
    }

    const ctr = document.querySelector('.calendar__date');
    removeChildren({parent: ctr, childName: 'calendar__number'});
    for(let dayElem of buildDays(currentYear, currentMonth)) {
        ctr.appendChild(dayElem);
    }
    document.getElementById('calendar_month').textContent = format(new Date(currentYear, currentMonth, 15), 'MMMM yyyy', {locale: fr});
};

function transformData(name, doc) {
    const datum = {
        id: doc.id,
        data: doc.data()
    };

    if (!periods[name]) {
        periods[name] = [datum]
    } else {
        periods[name].push(datum);
    }
}
